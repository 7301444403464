import React from "react"
import Layout from "../components/Layout"
import SEO from '../components/SEO'

const contact = () => {
  return <Layout>
<SEO title='Contacto'></SEO>
    <section className="contact-page">
      <article className='contact-form'>
        <h3>Contacto</h3>
        <form action="https://formspree.io/f/xgeprrny" method="POST">
          <div className="form-group">
            <input type="text" placeholder='Nombre' name='name' className='form-control'/>
            <input type="email" placeholder='E-Mail' name='email' className='form-control'/>
            <textarea name="message" rows="5" placeholder='Mensaje' name='message' className='form-control'></textarea>
            <button type="submit" className='submit-btn btn'>Enviar</button>
          </div>
        </form>
      </article>
    </section>
  </Layout>
}

export default contact
